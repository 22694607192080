<template>
  <div class="generic__banner__component generic__banner__component__banner">
    <!-- <pre> {{initialData}} </pre> -->
    <div id="Frames">
      <!--- OPTION2-->

      <div class="generic__banner_component container--is-maxwidth" :class="'hight--' + initialData.PresentationOption"
        v-if="initialData.PresentationOption === 'Option2'">
        <div class="generic__banner__component__section"
          :class="[initialData.BottomMargin28px === true ? 'margin-bottom' : initialData.TopMargin28px === true ? 'margin-top' : initialData.AddTopAndBottomMargins === true ? 'add-margin-top-bottom' : '']">
          <div :class="initialData.PresentationOption">
            <div class="generic__banner__component__text__title"
              v-bind:class="[initialData.TitleFontSize, initialData.DesktopText === '' ? 'margin_title' : '']"
              v-html="initialData.DesktopTitle" v-if="initialData.TitleAtHnTag == 'NotSelected' && !smallScreen">
            </div>
            <div class="generic__banner__component__text__title"
              v-bind:class="[initialData.TitleFontSize, initialData.DesktopText === '' ? 'margin_title' : '']"
              v-if="initialData.TitleAtHnTag != 'NotSelected' && !smallScreen">

              <h1 v-html="initialData.DesktopTitle" v-if="initialData.TitleAtHnTag == 'H1'"> </h1>
              <h2 v-html="initialData.DesktopTitle" v-if="initialData.TitleAtHnTag == 'H2'"> </h2>
              <h3 v-html="initialData.DesktopTitle" v-if="initialData.TitleAtHnTag == 'H3'"> </h3>
            </div>
            <div class="generic__banner__component__text__title"
              v-html="titleMobileText(initialData.MobileTitle, initialData.DesktopTitle, initialData.EnableMobileVersion)"
              v-if="smallScreen && initialData.TitleAtHnTag == 'NotSelected'"></div>
            <div class="generic__banner__component__text__title"
              v-if="smallScreen && initialData.TitleAtHnTag != 'NotSelected'">
              <h1
                v-html="titleMobileText(initialData.MobileTitle, initialData.DesktopTitle, initialData.EnableMobileVersion)"
                v-if="initialData.TitleAtHnTag == 'H1'"> </h1>
              <h2
                v-html="titleMobileText(initialData.MobileTitle, initialData.DesktopTitle, initialData.EnableMobileVersion)"
                v-if="initialData.TitleAtHnTag == 'H2'"> </h2>
              <h3
                v-html="titleMobileText(initialData.MobileTitle, initialData.DesktopTitle, initialData.EnableMobileVersion)"
                v-if="initialData.TitleAtHnTag == 'H3'"> </h3>

            </div>

            <div class="generic__banner__component__image parallax">
              <div class="focal-frames"
                v-if="(!smallScreen || (smallScreen && initialData.EnableMobileVersion === true && initialData.MobileImage === '') || (smallScreen && initialData.EnableMobileVersion === false))">

                <div class="focuspoint focal-frame" :style="getScreenWidth('option2')"
                  :data-focus-x="isRtl === false ? initialData.DesktopFocusX : ''"
                  :data-focus-y="isRtl === false ? initialData.DesktopFocusY : ''">
                  <span class="">
                    <img fetchpriority="high" loading="lazy" class="original" :src="initialData.New_Image" :alt="initialData.New_ImageAlt" />
                  </span>
                </div>
              </div>
              <div class="focal-frames"
                v-if="smallScreen && initialData.EnableMobileVersion === true && initialData.MobileImage !== ''">
                <div class="focuspoint focal-frame" :style="getScreenWidth('option2')"
                :data-focus-x="mobileFocusx(isRtl,initialData.MobileFocusX,initialData.DesktopFocusX,initialData.EnableMobileVersion)"
                :data-focus-y="mobileFocusy(isRtl,initialData.MobileFocusY,initialData.DesktopFocusY,initialData.EnableMobileVersion)">
                  <span class="">
                    <img loading="lazy" class="original"
                      :src="imageMobile(initialData.New_Image, initialData.MobileImage, initialData.EnableMobileVersion)" :alt="altMobile(initialData.New_ImageAlt, initialData.MobileImageAlt, initialData.EnableMobileVersion)" />
                  </span>
                </div>
              </div>
            </div>
            <div class="generic__banner__component__text__description" v-html="initialData.DesktopText"
              v-if="!smallScreen"></div>
            <div class="generic__banner__component__text__description" v-text="initialData.MobileText"
              v-html="titleMobileDescription(initialData.MobileText, initialData.DesktopText, initialData.EnableMobileVersion)"
              v-if="smallScreen">
            </div>

          </div>
        </div>
      </div>
      <!--- OPTIO2-->
      <!--- OPTION1-->
      <div class="generic__banner_component  container--is-maxwidth" :class="'hight--' + initialData.PresentationOption"
        v-if="initialData.PresentationOption == 'Option1'">
        <div class="generic__banner__component__section"
          :class="[initialData.BottomMargin28px === true ? 'margin-bottom' : initialData.TopMargin28px === true ? 'margin-top' : initialData.AddTopAndBottomMargins === true ? 'add-margin-top-bottom' : '']">
          <div :class="initialData.PresentationOption">

            <div class="generic__banner__component__text__title"
              v-bind:class="[initialData.TitleFontSize, initialData.DesktopText === '' ? 'margin_title' : '']"
              v-html="initialData.DesktopTitle" v-if="initialData.TitleAtHnTag == 'NotSelected' && !smallScreen">
            </div>
            <div class="generic__banner__component__text__title"
              v-bind:class="[initialData.TitleFontSize, initialData.DesktopText === '' ? 'margin_title' : '']"
              v-if="initialData.TitleAtHnTag != 'NotSelected' && !smallScreen">

              <h1 v-html="initialData.DesktopTitle" v-if="initialData.TitleAtHnTag == 'H1'"> </h1>
              <h2 v-html="initialData.DesktopTitle" v-if="initialData.TitleAtHnTag == 'H2'"> </h2>
              <h3 v-html="initialData.DesktopTitle" v-if="initialData.TitleAtHnTag == 'H3'"> </h3>
            </div>


            <div class="generic__banner__component__text__title"
              v-html="titleMobileText(initialData.MobileTitle, initialData.DesktopTitle, initialData.EnableMobileVersion)"
              v-if="smallScreen && initialData.TitleAtHnTag == 'NotSelected'"></div>
            <div class="generic__banner__component__text__title"
              v-if="smallScreen && initialData.TitleAtHnTag != 'NotSelected'">
              <h1
                v-html="titleMobileText(initialData.MobileTitle, initialData.DesktopTitle, initialData.EnableMobileVersion)"
                v-if="initialData.TitleAtHnTag == 'H1'"> </h1>
              <h2
                v-html="titleMobileText(initialData.MobileTitle, initialData.DesktopTitle, initialData.EnableMobileVersion)"
                v-if="initialData.TitleAtHnTag == 'H2'"> </h2>
              <h3
                v-html="titleMobileText(initialData.MobileTitle, initialData.DesktopTitle, initialData.EnableMobileVersion)"
                v-if="initialData.TitleAtHnTag == 'H3'"> </h3>

            </div>
            <div class="generic__banner__component__text__description" v-html="initialData.DesktopText"
              v-if="!smallScreen"></div>
            <div class="generic__banner__component__text__description"
              v-html="titleMobileDescription(initialData.MobileText, initialData.DesktopText, initialData.EnableMobileVersion)"
              v-if="smallScreen"> </div>
            <div class="generic__banner__component__image parallax">
              <div class="focal-frames"
                v-if="(!smallScreen || (smallScreen && initialData.EnableMobileVersion === true && initialData.MobileImage === '') || (smallScreen && initialData.EnableMobileVersion === false))">
                <div class="focuspoint focal-frame" :style="getScreenWidth('option1')"
                  :data-focus-x="isRtl === false ? initialData.DesktopFocusX : ''"
                  :data-focus-y="isRtl === false ? initialData.DesktopFocusY : ''">
                  <span class="">
                    <img fetchpriority="high" loading="lazy" class="original" :src="initialData.New_Image" :alt="initialData.New_ImageAlt"/>
                  </span>
                </div>
              </div>
              <div class="focal-frames"
                v-if="smallScreen && initialData.EnableMobileVersion === true && initialData.MobileImage !== ''">
                <div class="focuspoint focal-frame" :style="getScreenWidth('option1')"
                :data-focus-x="mobileFocusx(isRtl,initialData.MobileFocusX,initialData.DesktopFocusX,initialData.EnableMobileVersion)"
                :data-focus-y="mobileFocusy(isRtl,initialData.MobileFocusY,initialData.DesktopFocusY,initialData.EnableMobileVersion)">
                  <span class="">
                    <img loading="lazy" class="original"
                      :src="imageMobile(initialData.New_Image, initialData.MobileImage, initialData.EnableMobileVersion)" :alt="altMobile(initialData.New_ImageAlt, initialData.MobileImageAlt, initialData.EnableMobileVersion)"/>
                  </span>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
      <!--- OPTION1-->
      <!--- OPTION6-->
      <div class="generic__banner_component " :class="'hight--' + initialData.PresentationOption"
        v-if="initialData.PresentationOption == 'Option6'">
        <div :class="initialData.PresentationOption">
          <div class="generic__banner__component__section"
            :class="[initialData.BottomMargin28px === true ? 'margin-bottom' : initialData.TopMargin28px === true ? 'margin-top' : initialData.AddTopAndBottomMargins === true ? 'add-margin-top-bottom' : '']">

            <div class="generic__banner__component__image parallax">
              <div class="focal-frames"
                v-if="(!smallScreen || (smallScreen && initialData.EnableMobileVersion === true && initialData.MobileImage === '') || (smallScreen && initialData.EnableMobileVersion === false))">
                <div class="focuspoint focal-frame" :style="getScreenWidth()"
                  :data-focus-x="isRtl === false ? initialData.DesktopFocusX : ''"
                  :data-focus-y="isRtl === false ? initialData.DesktopFocusY : ''">
                  <span class="">
                    <img fetchpriority="high" loading="lazy" class="original" :src="initialData.New_Image" :alt="initialData.New_ImageAlt"/>
                  </span>
                </div>
              </div>
              <div class="focal-frames"
                v-if="smallScreen && initialData.EnableMobileVersion === true && initialData.MobileImage !== ''">
                <div class="focuspoint focal-frame" :style="getScreenWidth()"
                :data-focus-x="mobileFocusx(isRtl,initialData.MobileFocusX,initialData.DesktopFocusX,initialData.EnableMobileVersion)"
                :data-focus-y="mobileFocusy(isRtl,initialData.MobileFocusY,initialData.DesktopFocusY,initialData.EnableMobileVersion)">
                  <span class="">
                    <img loading="lazy" class="original"
                      :src="imageMobile(initialData.New_Image, initialData.MobileImage, initialData.EnableMobileVersion)" :alt="altMobile(initialData.New_ImageAlt, initialData.MobileImageAlt, initialData.EnableMobileVersion)"/>
                  </span>
                </div>
              </div>

            </div>


          </div>
        </div>
      </div>
      <!--- OPTION6-->
      <!------FullWidth --> <!--- working-->
      <div  v-if="initialData.PresentationOption === 'Option5'"
        :class="['generic__banner --full-width', initialData.TextpositiononImage, initialData.BottomMargin28px === true ? 'margin-bottom' : initialData.TopMargin28px === true ? 'margin-top' : initialData.AddTopAndBottomMargins === true ? 'add-margin-top-bottom' : '', initialData.GradientEnabled === true ? '--gradient' :'']">
        <div class="generic__banner--mobile__title" v-if="smallScreen">
          <span
            v-html="titleMobileText(initialData.MobileTitle, initialData.DesktopTitle, initialData.EnableMobileVersion)"
            v-if="initialData.TitleAtHnTag == 'NotSelected'">
          </span>
          <div v-if="initialData.TitleAtHnTag != 'NotSelected'"></div>
          <h1
            v-html="titleMobileText(initialData.MobileTitle, initialData.DesktopTitle, initialData.EnableMobileVersion)"
            v-if="initialData.TitleAtHnTag == 'H1'"> </h1>
          <h2
            v-html="titleMobileText(initialData.MobileTitle, initialData.DesktopTitle, initialData.EnableMobileVersion)"
            v-if="initialData.TitleAtHnTag == 'H2'"> </h2>
          <h3
            v-html="titleMobileText(initialData.MobileTitle, initialData.DesktopTitle, initialData.EnableMobileVersion)"
            v-if="initialData.TitleAtHnTag == 'H3'"> </h3>
        </div>
        <div class="generic__banner--image-placeholder" v-if="!smallScreen">
          <div class="focal-frames">
            <div class="focuspoint focal-frame" :style="getScreenWidth()"
              :data-focus-x="isRtl === false ? initialData.DesktopFocusX : ''"
              :data-focus-y="isRtl === false ? initialData.DesktopFocusY : ''">
              <img loading="lazy" class="original" :src="initialData.New_Image" :alt="initialData.New_ImageAlt"/>
            </div>
          </div>
        </div>
        <div class="generic__banner--image-placeholder" v-if="smallScreen">
          <div class="focal-frames">
            <div class="focuspoint focal-frame" :style="getScreenWidth()"
              :data-focus-x="mobileFocusx(isRtl,initialData.MobileFocusX,initialData.DesktopFocusX,initialData.EnableMobileVersion)"
              :data-focus-y="mobileFocusy(isRtl,initialData.MobileFocusY,initialData.DesktopFocusY,initialData.EnableMobileVersion)">
              <span>
                <img fetchpriority="high" loading="lazy" class="original"
                  :src="imageMobile(initialData.New_Image, initialData.MobileImage, initialData.EnableMobileVersion)" :alt="altMobile(initialData.New_ImageAlt, initialData.MobileImageAlt, initialData.EnableMobileVersion)" />
              </span>
            </div>
          </div>
        </div>
        <div
          :class="['generic__banner--content-placeholder', initialData.WhiteColorOption === true ? 'whitecolor-enabled' : '']">
          <div :class="['generic__banner--desktop__title', initialData.TitleFontSize]" v-if="!smallScreen">
            <span v-bind:class="[initialData.TitleFontSize, initialData.DesktopText === '' ? 'margin_title' : '']"
              v-html="initialData.DesktopTitle" v-if="initialData.TitleAtHnTag == 'NotSelected'">
            </span>
            <div v-if="initialData.TitleAtHnTag != 'NotSelected'">
              <h1 v-html="initialData.DesktopTitle" v-if="initialData.TitleAtHnTag == 'H1'"> </h1>
              <h2 v-html="initialData.DesktopTitle" v-if="initialData.TitleAtHnTag == 'H2'"> </h2>
              <h3 v-html="initialData.DesktopTitle" v-if="initialData.TitleAtHnTag == 'H3'"> </h3>
            </div>
          </div>
          <div class="generic__banner--description" v-html="initialData.DesktopText" v-if="!smallScreen"></div>
          <div class="generic__banner--description"
            v-html="titleMobileDescription(initialData.MobileText, initialData.DesktopText, initialData.EnableMobileVersion)"
            v-if="smallScreen"></div>
          <div class="generic__banner__cta" v-if="initialData.DesktopCTALabel != '' && !smallScreen && !initialData.EnableRoutineFinder">
            <a :href="initialData.DesktopCTALink" @click="gaTagging()" :class="ctaClass">
              <span class="btn__wrapper">{{ initialData.DesktopCTALabel}}</span>
            </a>
          </div>
          <div class="generic__banner__cta"
            v-if="smallScreen && initialData.MobileCTALabel != '' && initialData.EnableMobileVersion && initialData.EnableDSFService === false && !initialData.EnableRoutineFinder">
            <a :href="initialData.MobileCTALink" @click="gaTagging()" :class="ctaClass"><span class="btn__wrapper">
                {{ initialData.MobileCTALabel}}
              </span></a>
          </div>
          <div class="generic__banner__cta"
            v-if="!initialData.EnableRoutineFinder && smallScreen && initialData.DesktopCTALabel != '' && initialData.EnableMobileVersion == false && initialData.EnableDSFService === false">
            <a :href="initialData.DesktopCTALink" @click="gaTagging()" :class="ctaClass"><span class="btn__wrapper">
                {{ initialData.DesktopCTALabel }}
              </span></a>
          </div>
           
          <div class="generic__banner__cta " v-if="smallScreen && initialData.EnableDSFService === true">
            <spotscan-embed :data-tenant="dsfData.Tenant" :data-application="initialData.DSFApplication"
              :data-customer="dsfData.Customer" :data-country="dsfData.Country" :data-environment="dsfData.Environment"
              :data-touchpoint="dsfData.Touchpoint" :data-type="dsfData.Type"
              :data-loadingbg="dsfData.Loadingbackgroundcolor" :data-loadingmsg="dsfData.Loadingmessage"
              csrf-token="$WEBSITE_TOKEN" :switch-ecom="dsfData.Switchecom" :switch-autostart="dsfData.Switchautostart"
              :data-cta-label="ctalable(initialData.MobileCTALabel, initialData.DesktopCTALabel, initialData.EnableMobileVersion)"
              :data-pagecategory="dsfPageCategory" :data-cta="ctaClass" :data-dsf-tagging="dsfTagging"></spotscan-embed>
          </div>
        <!--routine-banner-->
<div v-if="initialData.EnableRoutineFinder === true">
          <button @click="fullWidth_Rb()" v-if="initialData.DesktopCTALabel != '' && !smallScreen" class="routineFinderStart cta cta--light">{{initialData.DesktopCTALabel}}</button>
          <button @click="fullWidth_Rb()"  v-if="initialData.EnableMobileVersion && smallScreen && initialData.MobileCTALabel != ''"  class="routineFinderStart cta cta--light" >{{initialData.MobileCTALabel}}</button>
          <button @click="fullWidth_Rb()" v-if="initialData.DesktopCTALabel != ''  && initialData.EnableMobileVersion == false && smallScreen" class="routineFinderStart cta cta--light" >{{initialData.DesktopCTALabel}}</button>
          <routine-finder v-if="showFullWidth_Rb"></routine-finder>
</div>
<!--routine-banner-->


        </div>
      </div>
      <!------FullWidth -->

      <!--HalfWidth-->
      <div
        :class="['generic__banner --half-width --right', initialData.BottomMargin28px === true ? 'margin-bottom' : initialData.TopMargin28px === true ? 'margin-top' : initialData.AddTopAndBottomMargins === true ? 'add-margin-top-bottom' : '']"
        v-if="initialData.PresentationOption === 'Option3'">
        <div class="generic__banner--mobile__title" v-if="smallScreen">
          <span
            v-html="titleMobileText(initialData.MobileTitle, initialData.DesktopTitle, initialData.EnableMobileVersion)"
            v-if="initialData.TitleAtHnTag == 'NotSelected'">
          </span>
          <div v-if="initialData.TitleAtHnTag != 'NotSelected'"></div>
          <h1
            v-html="titleMobileText(initialData.MobileTitle, initialData.DesktopTitle, initialData.EnableMobileVersion)"
            v-if="initialData.TitleAtHnTag == 'H1'"> </h1>
          <h2
            v-html="titleMobileText(initialData.MobileTitle, initialData.DesktopTitle, initialData.EnableMobileVersion)"
            v-if="initialData.TitleAtHnTag == 'H2'"> </h2>
          <h3
            v-html="titleMobileText(initialData.MobileTitle, initialData.DesktopTitle, initialData.EnableMobileVersion)"
            v-if="initialData.TitleAtHnTag == 'H3'"> </h3>
        </div>
        <div class="generic__banner--image-placeholder" v-if="!smallScreen">
          <div class="focal-frames">
            <div class="focuspoint focal-frame" :style="getScreenWidthHalf()"
              :data-focus-x="isRtl === false ? initialData.DesktopFocusX : ''"
              :data-focus-y="isRtl === false ? initialData.DesktopFocusY : ''">
              <img loading="lazy" class="original" :src="initialData.New_Image"  :alt="initialData.New_ImageAlt"/>
            </div>
          </div>
        </div>
        <div class="generic__banner--image-placeholder" v-if="smallScreen">
          <div class="focal-frames">
            <div class="focuspoint focal-frame" :style="getScreenWidthHalf('mobile')"
            :data-focus-x="mobileFocusx(isRtl,initialData.MobileFocusX,initialData.DesktopFocusX,initialData.EnableMobileVersion)"
            :data-focus-y="mobileFocusy(isRtl,initialData.MobileFocusY,initialData.DesktopFocusY,initialData.EnableMobileVersion)">
              <span>
                <img fetchpriority="high" loading="lazy" class="original"
                  :src="imageMobile(initialData.New_Image, initialData.MobileImage, initialData.EnableMobileVersion)" :alt="altMobile(initialData.New_ImageAlt, initialData.MobileImageAlt, initialData.EnableMobileVersion)"/>
              </span>
            </div>
          </div>
        </div>
        <div
          :class="['generic__banner--content-placeholder', initialData.WhiteColorOption === true ? 'whitecolor-enabled' : '']"
          v-if="!smallScreen">
          <div :class="['generic__banner--desktop__title', initialData.TitleFontSize]">
            <span v-bind:class="[initialData.TitleFontSize, initialData.DesktopText === '' ? 'margin_title' : '']"
              v-html="initialData.DesktopTitle" v-if="initialData.TitleAtHnTag == 'NotSelected'">
            </span>
            <div v-if="initialData.TitleAtHnTag != 'NotSelected'">
              <h1 v-html="initialData.DesktopTitle" v-if="initialData.TitleAtHnTag == 'H1'"> </h1>
              <h2 v-html="initialData.DesktopTitle" v-if="initialData.TitleAtHnTag == 'H2'"> </h2>
              <h3 v-html="initialData.DesktopTitle" v-if="initialData.TitleAtHnTag == 'H3'"> </h3>
            </div>
          </div>
          <div class="generic__banner--description" v-html="initialData.DesktopText"></div>
<!--routine-banner-->
<div v-if="initialData.EnableRoutineFinder === true">
          <button @click="rigHalfWidth_Rb()" v-if="initialData.DesktopCTALabel != '' && !smallScreen" class="routineFinderStart cta cta--light">{{initialData.DesktopCTALabel}}</button>
          <button @click="rigHalfWidth_Rb()"  v-if="initialData.EnableMobileVersion && smallScreen && initialData.MobileCTALabel != ''"  class="routineFinderStart cta cta--light" >{{initialData.MobileCTALabel}}</button>
          <button @click="rigHalfWidth_Rb()" v-if="initialData.DesktopCTALabel != '' &&  initialData.EnableMobileVersion == false && smallScreen" class="routineFinderStart cta cta--light" >{{initialData.DesktopCTALabel}}</button>
          <routine-finder v-if="showRigHalfWidth_Rb"></routine-finder>
</div>
<!--routine-banner-->

          <div class="generic__banner__cta " v-if="initialData.DesktopCTALabel != '' && !initialData.EnableRoutineFinder">
            <a :href="initialData.DesktopCTALink" :class="ctaClass" @click="gaTagging()">

              <span class="btn__wrapper">
                {{ initialData.DesktopCTALabel}}
              </span>

            </a>
          </div>
        </div>
        <div class="generic__banner--content-placeholder" v-if="smallScreen">

          <div class="generic__banner--description"
            v-html="titleMobileDescription(initialData.MobileText, initialData.DesktopText, initialData.EnableMobileVersion)">
          </div>
          <div class="generic__banner__cta "
            v-if="smallScreen && initialData.MobileCTALabel != '' && initialData.EnableMobileVersion && !initialData.EnableRoutineFinder">
            <a :href="initialData.MobileCTALink" @click="gaTagging()" :class="ctaClass"><span class="btn__wrapper">
                {{ initialData.MobileCTALabel}}
              </span></a>
          </div>
          <div class="generic__banner__cta "
            v-if="smallScreen && initialData.EnableMobileVersion == false && initialData.DesktopCTALabel != '' && !initialData.EnableRoutineFinder">
            <a :href="initialData.DesktopCTALink" @click="gaTagging()" :class="ctaClass"><span class="btn__wrapper">
                {{ initialData.DesktopCTALabel }}
              </span></a>
          </div>
          <!--routine-banner-->
<div v-if="initialData.EnableRoutineFinder === true">
          <button @click="rigHalfWidth_Rb()" v-if="initialData.DesktopCTALabel != '' && !smallScreen" class="routineFinderStart cta cta--light">{{initialData.DesktopCTALabel}}</button>
          <button @click="rigHalfWidth_Rb()"  v-if="initialData.EnableMobileVersion && smallScreen && initialData.MobileCTALabel != ''"  class="routineFinderStart cta cta--light" >{{initialData.MobileCTALabel}}</button>
          <button @click="rigHalfWidth_Rb()" v-if="initialData.DesktopCTALabel != ''  && initialData.EnableMobileVersion == false && smallScreen" class="routineFinderStart cta cta--light" >{{initialData.DesktopCTALabel}}</button>
          <routine-finder v-if="showRigHalfWidth_Rb"></routine-finder>
</div>
<!--routine-banner-->
        </div>
      </div>

      <div
        :class="['generic__banner --half-width --left', initialData.BottomMargin28px === true ? 'margin-bottom' : initialData.TopMargin28px === true ? 'margin-top' : initialData.AddTopAndBottomMargins === true ? 'add-margin-top-bottom' : '']"
        v-if="initialData.PresentationOption === 'Option4'">
        <div class="generic__banner--mobile__title" v-if="smallScreen">
          <span
            v-html="titleMobileText(initialData.MobileTitle, initialData.DesktopTitle, initialData.EnableMobileVersion)"
            v-if="initialData.TitleAtHnTag == 'NotSelected'">
          </span>
          <div v-if="initialData.TitleAtHnTag != 'NotSelected'"></div>
          <h1
            v-html="titleMobileText(initialData.MobileTitle, initialData.DesktopTitle, initialData.EnableMobileVersion)"
            v-if="initialData.TitleAtHnTag == 'H1'"> </h1>
          <h2
            v-html="titleMobileText(initialData.MobileTitle, initialData.DesktopTitle, initialData.EnableMobileVersion)"
            v-if="initialData.TitleAtHnTag == 'H2'"> </h2>
          <h3
            v-html="titleMobileText(initialData.MobileTitle, initialData.DesktopTitle, initialData.EnableMobileVersion)"
            v-if="initialData.TitleAtHnTag == 'H3'"> </h3>
        </div>

        <div
          :class="['generic__banner--content-placeholder', initialData.WhiteColorOption === true ? 'whitecolor-enabled' : '']"
          v-if="!smallScreen">
          <div :class="['generic__banner--desktop__title', initialData.TitleFontSize]">
            <span v-bind:class="[initialData.TitleFontSize, initialData.DesktopText === '' ? 'margin_title' : '']"
              v-html="initialData.DesktopTitle" v-if="initialData.TitleAtHnTag == 'NotSelected'">
            </span>
            <div v-if="initialData.TitleAtHnTag != 'NotSelected'">
              <h1 v-html="initialData.DesktopTitle" v-if="initialData.TitleAtHnTag == 'H1'"> </h1>
              <h2 v-html="initialData.DesktopTitle" v-if="initialData.TitleAtHnTag == 'H2'"> </h2>
              <h3 v-html="initialData.DesktopTitle" v-if="initialData.TitleAtHnTag == 'H3'"> </h3>
            </div>
          </div>
          <div class="generic__banner--description" v-html="initialData.DesktopText"></div> 

<!--routine-banner-->
<div v-if="initialData.EnableRoutineFinder === true">
          <button @click="leftHalfWidth_Rb()" v-if="initialData.DesktopCTALabel != '' && !smallScreen" class="routineFinderStart cta cta--light">{{initialData.DesktopCTALabel}}</button>
          <button @click="leftHalfWidth_Rb()"  v-if="initialData.EnableMobileVersion && smallScreen && initialData.MobileCTALabel != ''"  class="routineFinderStart cta cta--light" >{{initialData.MobileCTALabel}}</button>
          <button @click="leftHalfWidth_Rb()" v-if="initialData.DesktopCTALabel != '' && initialData.MobileCTALabel == '' && initialData.EnableMobileVersion == false && smallScreen" class="routineFinderStart cta cta--light" >{{initialData.DesktopCTALabel}}</button>
          <routine-finder v-if="showLeftHalfWidth_Rb"></routine-finder>
</div>
<!--routine-banner-->
          <div class="generic__banner__cta " v-if="initialData.DesktopCTALabel != '' && !initialData.EnableRoutineFinder">
            <a :href="initialData.DesktopCTALink" :class="ctaClass" @click="gaTagging()">

              <span class="btn__wrapper">
                {{ initialData.DesktopCTALabel }}
              </span>

            </a>
          </div>
        </div>
        <div class="generic__banner--image-placeholder" v-if="!smallScreen">
          <div class="focal-frames">
            <div class="focuspoint focal-frame" :style="getScreenWidthHalf()"
              :data-focus-x="isRtl === false ? initialData.DesktopFocusX : ''"
              :data-focus-y="isRtl === false ? initialData.DesktopFocusY : ''">
              <img loading="lazy" class="original" :src="initialData.New_Image" :alt="initialData.New_ImageAlt"/>
            </div>
          </div>
        </div>
        <div class="generic__banner--image-placeholder" v-if="smallScreen">
          <div class="focal-frames">
            <div class="focuspoint focal-frame" :style="getScreenWidthHalf('mobile')"
            :data-focus-x="mobileFocusx(isRtl,initialData.MobileFocusX,initialData.DesktopFocusX,initialData.EnableMobileVersion)"
            :data-focus-y="mobileFocusy(isRtl,initialData.MobileFocusY,initialData.DesktopFocusY,initialData.EnableMobileVersion)">
              <span>
                <img fetchpriority="high" loading="lazy" class="original"
                  :src="imageMobile(initialData.New_Image, initialData.MobileImage, initialData.EnableMobileVersion)" :alt="altMobile(initialData.New_ImageAlt, initialData.MobileImageAlt, initialData.EnableMobileVersion)"/>
              </span>
            </div>
          </div>
        </div>
        <div class="generic__banner--content-placeholder" v-if="smallScreen">
          <div class="generic__banner--description"
            v-html="titleMobileDescription(initialData.MobileText, initialData.DesktopText, initialData.EnableMobileVersion)">
          </div>
          <div class="generic__banner__cta " v-if="initialData.MobileCTALabel != '' && initialData.EnableMobileVersion && !initialData.EnableRoutineFinder ">
            <a :href="initialData.MobileCTALink" @click="gaTagging()" :class="ctaClass"><span class="btn__wrapper">
                {{ initialData.MobileCTALabel }}
              </span></a>
          </div>
          <div class="generic__banner__cta "
            v-if="initialData.EnableMobileVersion == false && initialData.DesktopCTALabel != '' && !initialData.EnableRoutineFinder">
            <a :href="initialData.DesktopCTALink" @click="gaTagging()" :class="ctaClass"><span class="btn__wrapper">
                {{ initialData.DesktopCTALabel }}
              </span></a>
          </div>
            <!--routine-banner-->
<div v-if="initialData.EnableRoutineFinder === true">
          <button @click="leftHalfWidth_Rb()" v-if="initialData.DesktopCTALabel != '' && !smallScreen" class="routineFinderStart cta cta--light">{{initialData.DesktopCTALabel}}</button>
          <button @click="leftHalfWidth_Rb()"  v-if="initialData.EnableMobileVersion && smallScreen && initialData.MobileCTALabel != ''"  class="routineFinderStart cta cta--light" >{{initialData.MobileCTALabel}}</button>
          <button @click="leftHalfWidth_Rb()" v-if="initialData.DesktopCTALabel != ''  && initialData.EnableMobileVersion == false && smallScreen" class="routineFinderStart cta cta--light" >{{initialData.DesktopCTALabel}}</button>
          <routine-finder v-if="showLeftHalfWidth_Rb"></routine-finder>
</div>
<!--routine-banner-->
        </div>

      </div>
      <!--HalfWidth-->
    </div>
    
   
  </div>

</template>

<script>
import SpotscanEmbed from '@Feature/SpotscanEmbed/code/Scripts/components/spotscan-embed.vue';
import LoadScript from '@Foundation/loadscript/loadscript';
import routineFinder from '@Feature/RoutineFinder/code/Scripts/components/routine-finder.vue';
import FocusPoint from '@Foundation/jquery.focuspoint';


export default {
  name: 'generic-banner',
  components: { SpotscanEmbed, LoadScript, routineFinder },

  props: {
    initialData: { type: Object, required: true },
    dsfData: { type: Object, required: true },
    dsfPageCategory: { type: String, required: true },
    ctaClass: { type: String, default: 'btn' },
    btnTagging: { type: Object, required: true },
	dsfTagging: {type: Object, required: true},
    isRtl: { type: Boolean, default: false }
  },

  data() {
    return {
      showRigHalfWidth_Rb : false,
      showLeftHalfWidth_Rb: false,
      showFullWidth_Rb: false,
      routineFinder:false,
      smallScreen: !window.matchMedia('(min-width: 1024px)').matches,
      imageData: '',
      screenSize: window.matchMedia('(max-width: 767px)').matches
        ? 'small'
        : window.matchMedia('(min-width: 768px) and (max-width: 1023px)')
          .matches
          ? 'medium'
          : 'large'
    };
  },
  mounted() {
    window.eventBus.$on('routineFinderClose:on', (value) => { 
      setTimeout(() => {
      this.showRigHalfWidth_Rb = value;
      this.showLeftHalfWidth_Rb = value;
      this.showFullWidth_Rb = value;
      }, 300);
    });
    let intervalId = setInterval(function() {        
      if (typeof jQuery) { 
        $(function () {
        $('.focuspoint').focusPoint();
      });
          clearInterval(intervalId);
      }
    }, 1000); 
    /*
          window.addEventListener("DOMContentLoaded", (event) => {
             this.focusMethod();
          }); */
    console.log('screen sizessss' + this.smallScreen);
  },
  methods: {
    /*focusMethod() {
       $('.focuspoint').focusPoint();
    },*/
    rigHalfWidth_Rb(){
      this.showRigHalfWidth_Rb = true;
    },
    leftHalfWidth_Rb(){
      this.showLeftHalfWidth_Rb = true;
    },
    fullWidth_Rb(){
      this.showFullWidth_Rb = true;
    },
 
    getScreenWidthHalf(param=null) {

      let screenWidth = '';
      let height = 256;
      var element = document.getElementById('content');
      var positionInfo = element.getBoundingClientRect();
      var width = positionInfo.width;
      if(param === 'mobile'){
         width = positionInfo.width -48;
      }
      if (this.screenSize == 'small') {
        height = 280;
        screenWidth = window.innerWidth;
        return 'width:' + width + 'px; height:' + height + 'px;';
      } else if (this.screenSize == 'medium') {
        height = 400;
        screenWidth = window.innerWidth;
        return 'width:' + width + 'px; height:' + height + 'px;';
      } else if (this.screenSize == 'large') {
        height = 330;
        screenWidth = 504;
        return 'width:' + screenWidth + 'px; height:' + height + 'px;';
      }
    },
    getScreenWidth(param = null) {

      let height = 460;
      var element = document.getElementById('content');
      var positionInfo = element.getBoundingClientRect();
      if (param === 'option2' || param === 'option1') {
        var width = positionInfo.width - 40;
      } else {
        var width = positionInfo.width;
      }
      var screenWidth = '';
      if (this.screenSize == 'small') {
        height = 270;
        screenWidth = window.innerWidth;
        return 'width:' + width + 'px; height:' + height + 'px;';
      } else if (this.screenSize == 'medium') {
        height = 400;
        screenWidth = window.innerWidth;
        return 'width:' + width + 'px; height:' + height + 'px;';
      } else if (this.screenSize == 'large') {
        height = 460;
        if (param === 'option2' || param === 'option1') {
          screenWidth = 1024;
          return 'width:100%; height:' + height + 'px;';
        } else {
          screenWidth = window.innerWidth;
          return 'width:100%; height:' + height + 'px;';
        }


      }

    },
    gaTagging() {
      if (this.btnTagging.category === 'error page') {

        window.dataLayer.push({
          event: 'uaevent',
          ecommerce: 'undefined',
          eventLabel: 'return::go back to home',
          eventAction: 'select',
          eventCategory: 'body',
        });
      } else {
        let eventLable = this.btnTagging.desktoplabel;
        if (this.smallScreen) {
          eventLable = this.btnTagging.desktoplabel;
        } else {
          eventLable = this.btnTagging.mobilelabel;
        }
        window.dataLayer.push({
          event: 'uaevent',
          event_name: 'body_button_click',
          ecommerce: 'undefined',
          eventLabel: eventLable,
          eventAction: this.btnTagging.action,
          eventCategory: this.btnTagging.category,
          eventAction: this.btnTagging.action,
          eventLabel: ctaname.toLowerCase() + '::' + url,
          cta_name: ctaname.toLowerCase(),
          link_url: url,
          module_name: this.decodeHtml(eventLable).toLowerCase(),

        });
      }
    },
    titleMobileText(MobileTitle, DesktopTitle, EnableMobileVersion) {
      var title = '';
      if (this.screenSize === 'medium' || this.screenSize === 'small') {
        if (EnableMobileVersion && MobileTitle != '') {
          title = MobileTitle
        } else if (EnableMobileVersion && MobileTitle == '') {
          title = DesktopTitle;
        } else if (EnableMobileVersion === false) {
          title = DesktopTitle;
        }

      }
      return title;

    },
    titleMobileDescription(MobileDescription, DesktopDescription, EnableMobileVersion) {
      var description = '';
      if (this.screenSize === 'medium' || this.screenSize === 'small') {
        if (EnableMobileVersion && MobileDescription != '') {
          description = MobileDescription
        } else if (EnableMobileVersion && MobileDescription == '') {
          description = DesktopDescription;
        } else if (EnableMobileVersion === false) {
          description = DesktopDescription;
        }

      }
      return description;

    },
    desktopalt(bannerlargealt, cropalt) {
      var altTag = cropalt;
      if (bannerlargealt) {
        altTag = bannerlargealt;
      }
      return altTag;
    },
    mobilealt(cropalt, mobile) {
      var mobileAltTag = cropalt;
      if (mobile) {
        mobileAltTag = mobile;
      }
      return mobileAltTag;

    },
    imageMobile(DesktopCropImageMedium, mobileimage, EnableMobileVersion) {
      var smallImage = '';
      if (EnableMobileVersion && mobileimage !== '') {
        smallImage = mobileimage;
      } else {
        smallImage = DesktopCropImageMedium;
      }

      return smallImage;

    },
    altMobile(New_ImageAlt, MobileImageAlt, EnableMobileVersion) {
      var mobileAlt = '';
      if (EnableMobileVersion && MobileImageAlt !== '') {
        mobileAlt = MobileImageAlt;
      } else {
        mobileAlt = New_ImageAlt;
      }

      return mobileAlt;

    },
    ctalable(mobile, deskstop, EnableMobileVersion) {
      var currentCtaLable = '';
      if (EnableMobileVersion) {
        if (mobile === '') {
          currentCtaLable = deskstop;
        } else {
          currentCtaLable = mobile;
        }
      } else {
        currentCtaLable = deskstop;
      }
      return currentCtaLable;

    },
    bgimage(cropimglarge, cropimgemedium, mobileimage) {
      var imgurl = `background-image:url('${cropimglarge}')`;
      if (this.screenSize === 'medium' || this.screenSize === 'small') {
        if (mobileimage) {
          imgurl = `background-image:url('${mobileimage}')`;
        }
        if (mobileimage == '') {
          imgurl = `background-image:url('${cropimgemedium}')`;
        }
      }
      return imgurl;
    },
    decodeHtml(html) {
      let div = document.createElement("div");
      div.innerHTML = html;
      let datas = div.innerText.replaceAll('\n', ' ');
      return datas;
    },
    mobileFocusx(rtl,mobileFoucs,desktopFocus,mobile){
     var focusx ='';
      if(mobile === true){
        focusx = mobileFoucs;
      }else{
        focusx = desktopFocus;
      }
      if(rtl === true){
        focusx ='';
      }
      return focusx;
    },
    mobileFocusy(rtl,mobileFoucs,desktopFocus,mobile){
      var focusy ='';
      if(mobile === true){
        focusy = mobileFoucs;
      }else{
        focusy = desktopFocus;
      }
      if(rtl === true){
        focusy ='';
      }
      return focusy;
    }

  }
};
</script>

<style lang='scss' src="./generic-banner.scss"></style>
<style lang='scss' src="./generic-banner-v2.scss"></style>
<style lang='css' src="./helper-tool.css"></style>
<style lang='css' src="./focuspoint.css"></style>
