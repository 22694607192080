<template>
  <div class="routine-finder-resultByEmail-container">
    <div class="routine-finder-resultByEmail" :class="{ 'hideCta': isOpened, 'showCta': isClosed }">

      <button @click="openemailformpopup()" class="routine-finder-resultByEmail__button">
        <img :src="datas.image.image_url" :alt="datas.image.image_alt" />
        <span v-truncate-rich-text="{ limit: 50, ellipse: false, singleLine: true }"></span>{{ datas.email_title }}
      </button>
    </div>


    <div class="emailForm-overlay"
      :class="{ 'open': isOpened, 'close': isClosed, 'desktopScreen': !smallScreen, 'smallScreen': smallScreen }">
    </div>
    <div
      :class="{ 'open': isOpened, 'close': isClosed, 'desktopScreen': !smallScreen, 'smallScreen': smallScreen, 'msgSentSucess': successMsg_container }"
      class="routine-finder-result__emailForm__contianer">
      <div class="routine-finder-result__emailForm__close" @click="close_emailformpopup()">

        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M8.15191 8.99995L0.576172 16.5757L1.4247 17.4242L9.00044 9.84848L16.5762 17.4242L17.4247 16.5757L9.84897 8.99995L17.4247 1.42421L16.5762 0.575684L9.00044 8.15142L1.4247 0.575684L0.576172 1.42421L8.15191 8.99995Z"
            fill="black" />
        </svg>


      </div>
      <div v-if="form_container" class="routine-finder-result__emailForm__innerWrapper">
        <h3 class="heading-h3" v-truncate-rich-text="{ limit: 55, ellipse: false, singleLine: false }"
          v-html="datas.title"></h3>
        <form @submit.prevent="handleSubmit">
          <div class="form-group inputbox-wrapper">
            <label for="emailId"
              v-truncate-rich-text="{ limit: 40, ellipse: false, singleLine: false }">{{ datas.email_label }}</label>
            <input type="email" id="emailId" v-model="getEmailId" @blur="checkEmailValidity"
              :placeholder="datas.sample_email" :class="{ 'emailInvalid': emailError }" />
            <div v-if="emailError" class="emailError">
              {{ datas.valid_email_label }}
            </div>
          </div>

          <div class="form-group checkbox-wrapper">
            <label for="checkbox">
              <input id="checkbox" type="checkbox" v-model="acceptedTerms" />
              <span class="customCheckbox"></span>
              <span v-truncate-rich-text="{ limit: 450, ellipse: false, singleLine: false }"
                class="customCheckboxText">{{ datas.email_accent }}</span>
            </label>
          </div>

          <button class="submitEmail cta cta--light" type="submit" :disabled="!isFormValid">{{ datas.cta_text }}</button>
        </form>
      </div>
      <div v-if="successMsg_container" class="routine-finder-result__emailForm__innerWrapper successMsg">
                  <div v-if="this.loadingIcon" class="loader-container">
                    <div class="loadMore-trigger"></div>
                    <transition name="fade">
                      <div class="loader-css">Loading</div>
                    </transition>
                  </div>
                  <div v-if="this.showSuccessMsg">
                    <div class="successMsg-title" v-truncate-rich-text="{ limit: 35, ellipse: false, singleLine: false }"
                    v-html="datas.sucess_title"></div>
                    <div class="successMsg-desc" v-truncate-rich-text="{ limit: 85, ellipse: false, singleLine: false }"
                    v-html="datas.success_message"></div>
                  </div>
      </div>

    </div>
  </div>
</template>
<script>
import { truncateRichText } from '@Foundation/directives/truncateRichText/truncateRichText';
import { AnalyticsHandler } from '@Foundation/analyticsHandler';
export default {
  name: 'routine-finder-result-by-email-form',
  directives: {
    truncateRichText
  },
  props: {
    datas: {
      type: {Object,String},
      required: true,
    },
    emailProductEan: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      getEmailId: '',
      emailError: false,
      acceptedTerms: false,
      smallScreen: window.matchMedia('(max-width: 650px)').matches,
      animationEnabled: false,
      initEmailpopup: false,
      isOpened: false,
      isClosed: true,
      successMsg_container: false,
      form_container: true,
      loadingIcon:true,
      showSuccessMsg: false,
    }
  },

  computed: {
    isEmailValid() {
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return emailPattern.test(this.getEmailId);
    },
    isFormValid() {
      return this.isEmailValid && this.acceptedTerms;
    },
  },
  methods: {
    async sendEmail() {
      this.gaTagging();
      const apiURL = '/api/routinefinder/RoutineFinderApiBus';
      const jsonData = {
        "Stage": 5,
        "Parameters": {
          "mailID": btoa(this.getEmailId),
          "origin": window.location.origin,
          "EAN": this.emailProductEan,
        }
      };
      try {
        const response = await fetch(apiURL, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json;charset=utf-8' },
          body: JSON.stringify(jsonData),
        });
        if (response.ok) {
          const data = await response.json(); // Convert response to JSON  
          this.apiData = data;
          if(this.apiData?.success === true){
            this.loadingIcon = false;
            this.showSuccessMsg = true;
          } 
        }
      } catch (error) {
        console.error('Error sending email:', error);
      }
    },
    openemailformpopup() {
      this.initEmailpopup = true;
      if (this.smallScreen) {
        this.isOpened = !this.isOpened;
        this.isClosed = !this.isClosed;
      }
      else {
        this.isOpened = true;
        this.isClosed = false;
      }
    },
    handleSubmit() {
      this.sendEmail();
      this.form_container = false;
      this.successMsg_container = true;
    },
    close_emailformpopup() {
      this.isOpened = false;
      this.isClosed = true;
      this.form_container = true;
      this.successMsg_container = false;
      this.getEmailId = '';
      this.acceptedTerms = '';
      this.emailError = false;
    },
    checkEmailValidity() {
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      this.emailError = !emailPattern.test(this.getEmailId);
    },
    gaTagging(){
      AnalyticsHandler.pushDataLayer(this.datas.email_results_tagging);
    }

  }
};
</script>

<style lang="scss" src="./routine-finder-result-by-email-form.scss"></style>