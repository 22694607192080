<template>
  <div class="routine_finder__banner routine_finder__banner__bg" :aria-label="this.bannerAlt"
    :style="{ backgroundImage: this.currentBackground }">
    <div class="routine_finder__banner__overlay"></div>
    <div class="routine_finder__banner__content">
      <div class="routine_finder__banner__BoxWrapper">
        <div class="routine_finder__banner__BoxContianer">
          <div class="routine_finder__banner__borderBox">
          </div>
          <div class="routine_finder__banner__borderText">
            <h2 class="routine_finder__banner__borderText__heading routineFinder-h2">{{ this.bannerdata.title.big_bold
              }}
            </h2>

            <p class="routine_finder__banner__borderText__descOne routineFinder-p">{{ this.bannerdata.title.medium_bold
              }}</p>
            <p class="routine_finder__banner__borderText__descTwo routineFinder-p">{{ this.bannerdata.title.normal }}
            </p>
          </div>

        </div>
      </div>
      <p class="routine_finder__banner__decscription routineFinder-p"
        v-truncate-rich-text="{ limit: 180, ellipse: true, singleLine: false }" v-html="bannerdata.description"></p>
      <button class="cta cta--light" @click="letStartCta(true)" v-if="bannerdata.cta_text">{{ this.bannerdata.cta_text
        }}</button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { truncateRichText } from '@Foundation/directives/truncateRichText/truncateRichText';
export default {
  name: 'routine-finder-banner',
  directives: {
    truncateRichText
  },
  props: {
    bannerdata: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      currentBackground: '',
      BannerMobile: this.bannerdata.mobile_image,
      BannerDesktop: this.bannerdata.desktop_image,
      bannerAlt: '',
    }
  },
  created() {
    this.setBackground();
    window.addEventListener('resize', this.setBackground);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setBackground);
  },
  computed:{
    ...mapGetters(['getIsHomePage']),

  },
  methods: {
    setBackground() {
      if (window.innerWidth <= 768) {
        this.currentBackground = `url('${this.BannerMobile.image_url}')`;
        this.bannerAlt = this.BannerMobile.image_alt;
      } else {
        this.currentBackground = `url('${this.BannerDesktop.image_url}')`;
        this.bannerAlt = this.BannerDesktop.image_alt;
      }
    },
    letStartCta(values) {
      window.eventBus.$emit('letstartCta:on', values);
      this.$store.commit('setIsHomePage', false);
    }
  }
};
</script>

<style lang="scss" src="./routing-finder-banner.scss"></style>